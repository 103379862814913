<template>
  <footer class="footer">
    <div>Copyright 2024 Ab Sadeghi-Nejad, MD. All rights reserved</div>
  </footer>
</template>

<script>
export default {};
</script>



