<template>
  <div class="card contact">
    <div class="card__text--contact">
      <p>
        For all media inquiries, please reach out via
        <a href="mailto:hello@dreamsofpersia.com">email</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>


