<template>
  <div class="card book">
    <div class="card__text">
      <div class="card__title">{{ title }}</div>
      <p>
        I left Iran in 1958 and do not speak Farsi on a regular basis.
        Nonetheless, I remain fluent in the language and continue to be fond of
        the colloquial Farsi sayings that I remember from childhood. Over the
        years, I have used these sayings frequently in my English conversation
        at home and on occasions when teaching at the medical school and
        hospital. <br /><br />Some three years ago, Nathan asked me to jot down
        the sayings that he and Adrienne had heard me use over the years. It was
        a reasonable request and an easy task, so I agreed immediately. Several
        months later when we were talking about it, he said that he wanted the
        sayings written in both English and Farsi. This made the project
        significantly more difficult. Reading Farsi words written on the
        computer or typewriter is very difficult for the uninitiated. My
        handwriting was never good in either language. In time, a dislocated
        disk, muscular dystrophy (FSHMD), and aging also took their toll so my
        penmanship became even worse.<br /><br />
        After several false starts on this project, I remembered that my nephew,
        Hossein, had learned Persian calligraphy as a child and had good
        handwriting. I asked him for help and he agreed. He wrote and updated
        the Farsi versions so they are both legible and beautiful. Last year
        Adrienne recommended putting my sayings into a book with artwork,
        instead of a typewritten pamphlet as I had intended, and volunteered to
        help. Sadly, once that was done, we found out that the publisher could
        not reproduce Sainy's calligraphy. So I had to settle for a computer
        generated Farsi alphabet. <br /><br />
        I have included only the sayings that I have used in my daily
        conversation, so the list is by no means comprehensive or complete. For
        each saying there is the English translation in italics and, when
        appropriate, the background and context for them. In Farsi, third person
        singular (he, she, his, her etc.) is not separated by gender, so in this
        book he his applies to all genders. The order of the sayings is totally
        random. To help the non- Farsi speakers with pronunciations, I have put
        the part of the word that is emphasized in bold. In the phonetics, the
        sound of single a (a) is like a in "ash" and of double a (aa) is like a
        in "all". Thank you Nate for suggesting the project, Sainy for the
        calligraphy, Adey for recommending the book format as well as enormous
        help to bring the idea to fruition, and M for editing and proofreading.
        Without your help and support this book would not have seen the light of
        day.
      </p>
    </div>
    <div class="book__gallery">
      <img
        class="book__img zoom"
        src="@/assets/img/BlackRopeEn.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/BlackRopeFarsi.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/BloodMoneyEn.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/BloodMoneyFarsi.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/ZeerehEn.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/ZeerehFarsi.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/MiceEarsEn.png"
        alt="book gallery image"
      />
      <img
        class="book__img zoom"
        src="@/assets/img/MiceEarsFarsi.png"
        alt="book gallery image"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "THE BOOK",
    };
  },
};
</script>
